module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NNG2R2N","includeInDevelopment":false,"selfHostedOrigin":"https://gtm.testmottagningen.se/","defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#e5e4df","display":"minimal-ui","icon":"src/images/framework/favicon.png","name":"Testmottagningen","short_name":"Testmottagningen","start_url":"/","theme_color":"#e5e4df","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2c51cd114ffff43125288bdac3f93e99"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"sv","i18nextOptions":{"defaultNS":"translations","fallbackLng":"sv","interpolation":{"escapeValue":false},"keySeparator":true,"ns":"translations"},"languages":["en","sv"],"localeJsonSourceName":"locale","redirect":false,"siteUrl":"https://www.testmottagningen.se"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
